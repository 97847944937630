.tabs {
  background-color: rgba(0, 0, 0, 0) !important;
}

.tabs .indicator {
  background-color: whitesmoke !important;
}

.tabs .tab a {
  color: black !important;
}

.tabs .tab a:focus.active {
  background-color: rgba(0, 239, 255, 0.05) !important;
}

.section {
  padding-top: 6vw !important;
  padding-bottom: 4vw !important;
}
