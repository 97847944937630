textarea {
  color: black !important;
}

input {
  color: black !important;
}

.btn {
  background-color: white !important;
  color: black !important;
  margin-top: 5pt;
  margin-bottom: 5pt;
}

.input-field .prefix {
  color: black !important;
}

/* Not working */
.dataset-plot {
  width: 100%;
}

/* .input-field {
  color: black !important;
} */

/* input textarea.materialize-textarea {
  border-bottom: brown !important;
  box-shadow: brown !important;
} */

/* label focus color */
/* .input-field input:focus + label {
  color: red !important;
} */
/* label underline focus color */
.row .input-field input:focus {
  border-bottom: 1px solid rgba(0, 239, 255) !important;
  box-shadow: 0 1px 0 0 rgba(0, 239, 255) !important;
}

.row .input-field textarea:focus {
  border-bottom: 1px solid rgba(0, 239, 255) !important;
  box-shadow: 0 1px 0 0 rgba(0, 239, 255) !important;
}

.section2 {
  padding-top: 1vw !important;
  padding-bottom: 1vw !important;
}

.collapsible-header {
  padding: 0.3rem !important;
  background-color: rgba(247, 247, 247) !important;
}

.card-panel {
  padding: 1rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.input-field {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.btn {
  margin-right: 20px !important ;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: rgba(0, 0, 0, 0.54) !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(0, 239, 255, 0.04) !important;
}

.progress .determinate {
  background-color: rgb(7, 136, 146) !important;
}

#space {
  height: 50px !important;
}