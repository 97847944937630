:root {
  --clear-color: rgba(0, 0, 0, 0);
  --half-clear-white: rgba(255, 255, 255, 0.5);
  --main-background-color: white;
  /* --main-text-color: rgb(36, 0, 94); */
  --main-text-color: rgb(36, 36, 36);
  --menu-text-color: white;
  /* --main-background-image: url(img/shutterstock_416086279.png); */
  /* --main-background-image: url(img/shutterstock_685008184.png); */
  /* --main-background-image: url(img/shutterstock_713390011.jpg); */
}

.page {
  color: var(--main-text-color);
  min-height: 88vh;
}

/* .nav-wrapper {
  background-color: var(--main-background-color) !important;
} */

.sidenav {
  /* background-image: var(--main-background-image); */
  background-size: cover;
  background-position: center;
  background-color: var(--main-background-color) !important;
}

.sidenav li > a {
  color: var(--main-text-color) !important;
}

.page-footer {
  background-color: var(--clear-color) !important;
  color: var(--main-text-color) !important;
  text-align: center;
}

html {
  /* background-image: var(--main-background-image); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--main-background-color) !important;
}

nav a {
  color: var(--menu-text-color) !important;
}
