.logo-l img {
  padding-top: 18px;
  height: 52px;
}

.logo-m img {
  padding-top: 18px;
  padding-left: 40px;
  height: 48px;
}

.logo-s img {
  padding-top: 16px;
  padding-left: 30px;
  height: 48px;
}

header {
  /* background: url(../img/Banner4.jpg); */
  /* background: url(../img/shutterstock_685008184.png); */
  background: url(../img/navbar_image.jpg);
  /* transition: background 5s linear; */
  /* background-size: 100%; */
  background-repeat: no-repeat;
  background-size: cover;
  /* min-height: 360px; */
  background-position: bottom;
  /* max-width: 100%; */
  height: auto;
}

.dropdown-content li > a {
  background-color: #059CCC;
  color: white !important;
}

.no-shadows {
  box-shadow: none !important;
}

.sidenav li > a {
  font-size: 120% !important;
}

nav ul a {
  font-size: 120% !important;
}

.sidenav-space {
  padding-top: 30px;
}

:focus {
  outline: none !important;
}

/* nav ul a:hover {
  background-color: #059CCC !important;
} */

